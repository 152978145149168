<template>
  <div class="main" style="padding: 20px">
    <!-- Loader -->

    <!-- Confirmation Module -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="deleteConfirmationdialog"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Questo elemento verrà eliminato definitivamente</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="deleteConfirmationdialog = false"
            >ANNULLA</v-btn
          >
          <v-btn color="green" dark @click="deleteMachina">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Saving Confirmation Dialouge -->
    <!--Second Navbar with Files layout -->
    <v-dialog
      content-class="vehDialog"
      style="overflow-x: hidden !important"
      :scrollable="false"
      v-model="dialoginfo"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Dettaglio delle azioni per questa pagina
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="" x-small @click="dialoginfo = false">
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-list disabled>
            <v-subheader>Azioni</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in actionItems" :key="i">
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content style="text-align: left !important">
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Macchina Dialog To Save or Update The Macchina -->
    <v-dialog
      content-class="vehDialog"
      :scrollable="false"
      persistent
      v-model="MacchinaFormDialog"
      width="1200"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Aggiungi Macchina</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <Button
            classe_bottone="mr-n8"
            colore_icona="success"
            idForm="MACCHINA"
            idOggetto="SALVA_MACCHINA"
            mdi_icona="mdi-floppy"
            stile_icona="float: left"
            testo_tooltip="Salva Macchina"
            v-on:buttonClick="submit"
          >
          </Button>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="closeModal" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col class="py-0" cols="3" md="2">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_CODICE_MACCHINA"
                    label_input="Codice Macchina"
                    palceholder_input="Codice Macchina"
                    :vmodel="cod_macchina"
                    v-on:changeText="(val) => (this.cod_macchina = val)"
                  />
                </v-col>
                <v-col class="py-0" cols="4" md="5">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_DES_MACCHINA"
                    label_input="Descrizione Macchina"
                    palceholder_input="Descrizione Macchina"
                    :vmodel="des_macchina"
                    v-on:changeText="(val) => (this.des_macchina = val)"
                  />
                </v-col>
                <v-col class="py-0" cols="3">
                  <v-autocomplete
                    v-model="id_tipo_get_macchina"
                    :items="tipo_macchina_list"
                    item-text="des"
                    label="Tipo"
                    :placeholder="id_tipo_get_macchina"
                    required
                    item-value="id_tabella"
                  >
                    <template #label>
                      <span class="">Tipo</span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="gettabelle"
                          tipo="TIPO_GET_MACCHINA"
                          nome="Tipo"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" cols="5" md="2">
                  <v-autocomplete
                    v-model="id_stato"
                    :items="des_stato_list"
                    item-text="des"
                    label="Stato"
                    :placeholder="des_stato"
                    required
                    item-value="id_tabella"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" md="3" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_MATRICOLA_MACCHINA"
                    label_input="Matricola Macchina"
                    palceholder_input="Matricola Macchina"
                    :vmodel="matricola"
                    v-on:changeText="(val) => (this.matricola = val)"
                  />
                </v-col>
                <v-col cols="4" md="2" class="py-0">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="data_acquisto_formato"
                        label="Data Acquisto"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data_acquisto"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" md="2" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_MARCA_MACCHINA"
                    label_input="Marca Macchina"
                    palceholder_input="Marca Macchina"
                    :vmodel="marca"
                    v-on:changeText="(val) => (this.marca = val)"
                  />
                </v-col>
                <v-col cols="4" md="2" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_DES_CONNESSIONE_MACCHINA"
                    label_input="Des. Connessione"
                    palceholder_input="Des. Connessione"
                    :vmodel="des_connessione"
                    v-on:changeText="(val) => (this.des_connessione = val)"
                  />
                </v-col>
                <v-col cols="4" md="3" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_MODELLO_MACCHINA"
                    label_input="Modello"
                    palceholder_input="Modello"
                    :vmodel="modello"
                    v-on:changeText="(val) => (this.modello = val)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-0">
                  <v-switch
                    v-model="multi_commessa"
                    :false-value="0"
                    :true-value="1"
                    :label="'Multicommessa'"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-switch
                    v-model="autonoma"
                    :false-value="0"
                    :true-value="1"
                    :label="'Autonoma'"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-switch
                    v-model="on_line"
                    :false-value="0"
                    :true-value="1"
                    :label="'Online'"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="py-0 mb-1">
                  <v-switch
                    v-model="tipo_connessione"
                    :false-value="0"
                    :true-value="1"
                    :label="'Tipo Connessione'"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="py-0 mt-1">
                  <v-switch
                    v-model="macchina_4"
                    :false-value="0"
                    :true-value="1"
                    :label="'4.0'"
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="py-0 mt-1">
                  <v-switch
                    v-model="schedula"
                    :false-value="0"
                    :true-value="1"
                    :label="'Schedula'"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" md="3" sm="3" class="py-0">
                  <v-select
                    v-model="id_reparto"
                    :items="des_reparto_list"
                    item-text="des"
                    item-value="id_tabella"
                    label="Reparto"
                    :placeholder="des_reparto"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4" md="2" sm="3" class="py-0">
                  <v-select
                    v-model="id_funzione"
                    :items="des_funzione_list"
                    item-text="des"
                    item-value="id_tabella"
                    label="Funzione"
                    :placeholder="des_funzione"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4" md="3" sm="3" class="py-0">
                  <v-select
                    v-model="id_centrocosto"
                    :items="des_controcosto_list"
                    item-text="des"
                    item-value="id_tabella"
                    label="CentroCosto"
                    :placeholder="des_centrocosto"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2" sm="2" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_ORA_DISPONIBILITA_MACCHINA"
                    label_input="Ora Disponibilita"
                    palceholder_input="Ora Disponibilita"
                    :vmodel="ore_disponibilita"
                    :min_input="0"
                    type_input="number"
                    v-on:changeText="(val) => (this.ore_disponibilita = val)"
                  />
                </v-col>
                <v-col cols="2" md="2" sm="2" class="py-0">
                  <TextField
                    idForm="MACCHINA"
                    idOggetto="CREA_COSTO_ORARIO_MACCHINA"
                    label_input="Costo Orario"
                    palceholder_input="Costo Orario"
                    :vmodel="costo_orario"
                    :min_input="0"
                    type_input="number"
                    v-on:changeText="(val) => (this.costo_orario = val)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="py-0">
                  <v-menu
                    v-model="menu_inizio_garanzia"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="inizio_garanzia_formato"
                        label="Inizio Garanzia"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="inizio_garanzia"
                      @input="menu_inizio_garanzia = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-menu
                    v-model="menu_fine_garanzia"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="fine_garanzia_formato"
                        label="Fine Garanzia"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fine_garanzia"
                      @input="menu_fine_garanzia = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3" class="d-flex py-0" cols="8" md="8">
                  <v-textarea
                    style="height: 150px"
                    placeholder="Specifiche"
                    v-model="specifiche"
                    label="Specifiche"
                  ></v-textarea>
                </v-col>
                <v-col class="py-0" cols="12" md="4">
                  <v-row class="mt-6">
                    <v-col md="2" cols="4" class="mt-6">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-16"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogAggiungiAllegato = true"
                          >
                            <v-icon color="#1a80b6">mdi-image</v-icon>
                          </v-btn>
                        </template>
                        <span>Nuova Immagine dell'Macchina</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      v-if="macchina_img"
                      md="8"
                      cols="12"
                      class="d-flex justify-center"
                    >
                      <div
                        v-if="!isPdf(macchina_img)"
                        v-viewer
                        style="top: 20px"
                        class="images clearfix"
                      >
                        <img
                          id="immagine"
                          style="
                            height: 80% !important;
                            width: 100%;
                            display: block !important;
                            margin-left: 2rem !important;
                          "
                          :src="macchina_img"
                          :lazy-src="macchina_img"
                          aspect-ratio="1"
                          class="grey lighten-2"
                        />
                      </div>
                      <v-card
                        @click="openPdfAppenaCaricato(macchina_img)"
                        style="height: 40px !important; width: 80px !important"
                        v-if="isPdf(macchina_img)"
                      >
                        <div style="text-align: center">
                          <v-spacer></v-spacer>
                          <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- To view Pdf Image -->
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="height: 80vh !important; width: 90vw !important"
      >
      </iframe>
    </v-dialog>
    <!-- Dialog to Add new File to Articolo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAggiungiAllegato"
      width="500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Allegati all'Macchina</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setImmagine()" small>
                <v-icon v-on="on" color="success" v-bind="attrs"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-n4"
                @click="dialogAggiungiAllegato = false"
                small
              >
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image,image/PNG"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Main Table For showing the List of Macchina -->
    <v-hover>
      <template v-slot="{ hover }">
        <v-card :elevation="hover ? 24 : 2" class="main-form" dense>
          <v-toolbar dense color="indigo">
            <v-toolbar-title class="white--text"
              >Impianti
              <v-icon color="white"
                >mdi-robot-industrial</v-icon
              ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <Button
              classe_bottone="mr-1"
              colore_icona="primary"
              idForm="MACCHINA"
              idOggetto="CREA_MACCHINA"
              mdi_icona="mdi-plus"
              testo_tooltip="Nuova Macchina"
              v-on:buttonClick="openModal"
            >
            </Button>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialoginfo = true"
                  v-on="on"
                  v-bind="attrs"
                  small
                >
                  <v-icon color="primary"> mdi-information-outline </v-icon>
                </v-btn>
              </template>
              <span>Dettaglio delle azioni</span>
            </v-tooltip>
            <v-text-field
              style="background-color: white !important"
              class="ml-2"
              v-model="search"
              label="Cerca"
              single-line
              hide-details
            >
              <v-icon slot="append" color="primary"> mdi-magnify </v-icon>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
                  <v-icon color="error">mdi-keyboard-backspace</v-icon>
                </v-btn>
              </template>
              <span>Vai alla Dashboard</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-subtitle
            style="width: 30% !important; float: right !important"
          >
          </v-card-subtitle>
          <v-card-text style="max-height: 600px; overflow-y: auto">
            <v-data-table
              :loading="loading"
              dense
              :headers="showHeaders"
              :items-per-page="7"
              :search="search"
              :items="MacchinaLista"
              class="elevation-1 tablestyle"
            >

              <template v-slot:[`item.edit`]="{ item }">
                <v-row>
                  <v-col md="4">
                    <Button
                      classe_bottone=""
                      colore_icona="primary"
                      idForm="MACCHINA"
                      idOggetto="MODIFICA_MACCHINA"
                      mdi_icona="mdi-pencil"
                      stile_icona=""
                      testo_tooltip="Aggiornare Macchina"
                      v-on:buttonClick="
                        setmacchina(item);
                        getimageMacchina(item);
                      "
                    >
                    </Button>
                  </v-col>
                  <v-col class="ml-6" md="4">
                    <Button
                      classe_bottone=""
                      colore_icona="#1a80b6"
                      idForm="MACCHINA"
                      idOggetto="VISUALIZZA_MACCHINA"
                      mdi_icona="mdi-magnify"
                      stile_icona=""
                      testo_tooltip="Dettaglio scheda"
                      v-on:buttonClick="handleClick(item)"
                    >
                    </Button>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <Button
                  classe_bottone="mr-5"
                  colore_icona="error"
                  idForm="MACCHINA"
                  idOggetto="ELIMINA_MACCHINA"
                  mdi_icona="mdi-window-close"
                  stile_icona="float: left"
                  testo_tooltip="Rimuovere Macchina"
                  v-on:buttonClick="selectMachina(item)"
                >
                </Button>
              </template>
              <template v-slot:[`item.on_line`]="{ item }">
                <v-icon v-if="item.on_line === 1">mdi-checkbox-outline</v-icon>
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
              <template v-slot:[`item.multi_commessa`]="{ item }">
                <v-icon v-if="item.multi_commessa === 1"
                  >mdi-checkbox-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
              <template v-slot:[`item.autonoma`]="{ item }">
                <v-icon v-if="item.autonoma == 1"
                  >mdi-checkbox-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
              <template v-slot:[`item.schedula`]="{ item }">
                <v-icon v-if="item.schedula === 1"
                  >mdi-checkbox-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
              <template v-slot:[`item.tipo_connessione`]="{ item }">
                <v-icon v-if="item.tipo_connessione === 1"
                  >mdi-checkbox-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
    <!-- SnackBar to Show the Alerts Presents on this page -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";

import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";
export default {
  components: { Button, TextField, Modules: () => import("@/components/TOOLS/Modules"), },
  name: "VehicleDetail",
  data() {
    return {
      // Variable for Snackbar
      macchina_4: false,
      allegato: "",
      selectedPdf: false,
      overlay: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // Dialogs And Progress Loader Presents on this Page
      dialogAggiungiAllegato: false,
      pdfdialog: false,
      macchina_img: "",
      dialoginfo: false,
      deleteConfirmationdialog: false,
      MacchinaFormDialog: false,
      progressBar: false,
      loading: false,
      //////////////////////////// Variables Needed to Create Updated and render data ///////////////////////////////
      MacchinaLista: [],
      selectedMacchina: {},
      id_centrocosto: "",
      id_funzione: "",
      id_macchina: "",
      id_reparto: "",
      id_stato: "",
      id_tipo_get_macchina: null,
      id_utente: null,
      des_stato: "",
      des_reparto: "",
      des_centrocosto: "",
      des_funzione: "",
      des_connessione: "",
      des_macchina: "",
      des_funzione_list: [],
      des_stato_list: [],
      tipo_macchina_list: [],
      des_reparto_list: [],
      des_controcosto_list: [],
      item: "",
      autonoma: 0,
      cod_macchina: "",
      data_acquisto: "",
      inizio_garanzia: "",
      fine_garanzia: "",
      date_ins: new Date().toISOString().substr(0, 10),
      date_mod: new Date().toISOString().substr(0, 10),
      marca: "",
      matricola: "",
      modello: "",
      multi_commessa: 0,
      on_line: 0,
      schedula: 0,
      ore_disponibilita: 0,
      costo_orario: null,
      specifiche: null,
      tipo_connessione: 0,
      validita: 0,
      menu3: false,
      menu_fine_garanzia: false,
      menu_inizio_garanzia: false,
      search: "",
      headers: [],
      formsPermission: [
        {
          id: 1,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_CODICE_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 2,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_DES_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 3,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_ID_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 4,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_MATRICOLA_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 5,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_MARCA_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 6,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_DES_CONNESSIONE_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 7,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_ORA_DISPONIBILITA_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 8,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_COSTO_ORARIO_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 9,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_DATA_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
        {
          id: 10,
          id_form: "MACCHINA",
          nome_oggetto: "CREA_SPECIFICHE_MACCHINA",
          flag_readonly: 0,
          flag_hide: 0,
          flag_obbligatorio: 0,
        },
      ],
      //////////////////////////// Items for Action Dialog ///////////////////////////////
      actionItems: [
        {
          id: 1,
          icon: "mdi-plus",
          text: "Per aggiungere una nuova macchina",
          color: "#1a80b6",
        },
        {
          id: 2,
          icon: "mdi-pencil",
          text: "Per aggiornare la macchina esistente",
          color: "#009999",
        },
        {
          id: 3,
          icon: "mdi-magnify",
          text: "Per vedere la scheda della macchina",
          color: "#1a80b6",
        },
        {
          id: 4,
          icon: "mdi-window-close",
          text: "Per eliminare una macchina",
          color: "#c00000",
        },
      ],
      selectedHeaders: [],
      // Macchina Table Headers
      headersMap: {
        edit: {
          align: "start",
          value: "edit",
          sortable: false,
          width: "10px",
        },
        detail: {
          align: "start",
          value: "detail",
          sortable: false,
        },
        autonoma: {
          text: "Autonoma",
          value: "autonoma",
          sortable: false,
        },
        cod_macchina: {
          text: "Cod. Macchina",
          value: "cod_macchina",
          sortable: false,
          width: 1,
        },
        data_acquisto: {
          text: "Data Acquisto",
          value: "data_acquisto",
          sortable: false,
          width: 2,
        },
        inizio_garanzia: {
          text: "Inizio Garanzia",
          value: "inizio_garanzia",
          sortable: false,
          width: 2,
        },
        fine_garanzia: {
          text: "Fine Garanzia",
          value: "fine_garanzia",
          sortable: false,
          width: 2,
        },
        des_connessione: {
          text: "Des Connessione",
          value: "des_connessione",
          sortable: false,
        },
        des_macchina: {
          text: "Des Macchina",
          value: "des_macchina",
          sortable: false,
        },
        des_centrocosto: {
          text: "Centrocosto",
          value: "des_centrocosto",
          sortable: false,
        },
        des_funzione: {
          text: "Funzione",
          value: "des_funzione",
          sortable: false,
        },
        des_reparto: {
          text: "Reparto",
          value: "des_reparto",
          sortable: false,
        },
        des_stato: {
          text: "Stato",
          value: "des_stato",
          sortable: false,
        },
        marca: {
          text: "Marca",
          value: "marca",
          sortable: false,
        },
        matricola: {
          text: "Matricola",
          value: "matricola",
          sortable: false,
        },
        modello: {
          text: "Modello",
          value: "modello",
          sortable: false,
        },
        multi_commessa: {
          text: "Multi Commessa",
          value: "multi_commessa",
          sortable: false,
        },
        on_line: {
          text: "On Line",
          value: "on_line",
          sortable: false,
        },
        schedula: {
          text: "Schedulazione",
          value: "schedula",
          sortable: false,
        },
        ora_disponibilita: {
          text: "Ora Disponibilita",
          value: "ore_disponibilita",
          sortable: false,
        },
        specifiche: {
          text: "Specifiche",
          value: "specifiche",
          sortable: false,
        },
        tipo_connessione: {
          text: "Tipo Connessione",
          value: "tipo_connessione",
          sortable: false,
        },
        delete: {
          text: "",
          value: "delete",
          sortable: false,
        },
      },
      selectedItem: null,
    };
  },
  methods: {
    // get image
    getimageMacchina(item) {
      let finale = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getmacchinapreviewimg",
          token: localStorage.getItem("user_token"),
          id_macchina: item.id_macchina,
        },
      };
      this.$store.dispatch("api", finale).then((res) => {
        this.macchina_img = res.data.result[0].macchina_img;
      });
    },
    // to open Modal for PDF
    openPdfAppenaCaricato(item) {
      if (item.startsWith("data:application/pdf")) {
        this.selectedPdf = item;
        this.pdfdialog = true;
      }
    },
    // Function to handle pasted url of image
    onPaste(e) {
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;
        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.macchina_img = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name
      );
    },
    hideIcons() {
      this.hideicons = false;
      this.on = false;
      this.openAll = true;
      this.open = this.ids;
    },
    // Function to select an image and change it to base64
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        this.hasExtension(files[0], [
          ".jpeg",
          ".gif",
          ".png",
          ".jpg",
          ".pdf",
          ".PNG",
        ])
      ) {
        this.allegato = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;
        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          that.macchina_img = reader.result;
          localStorage.setItem("MacchinaImage", that.macchina_img);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        this.dialogAggiungiAllegato = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non valido";
        this.snackbar_text_color = "white";
      }
      this.dialogAggiungiAllegato = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");
        // Create an image
        var img = new Image();
        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };
        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;
        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
      this.dialogAggiungiAllegato = false;
    },
    // In order to resize the image
    resizeImage(img, id) {
      if (img.width >= img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    // Method to get macchina to delete
    selectMachina(item) {
      this.selectedMacchina = item;
      this.deleteConfirmationdialog = true;
    },
    // Go back Radial Menu
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    //  Populating the Form with exisiting value
    setmacchina(item) {
      this.MacchinaFormDialog = true;
      this.autonoma = item.autonoma;
      this.cod_macchina = item.cod_macchina;
      this.data_acquisto = item.data_acquisto;
      this.inizio_garanzia = item.inizio_garanzia;
      this.fine_garanzia = item.fine_garanzia;
      this.des_connessione = item.des_connessione;
      this.des_macchina = item.des_macchina;
      this.id_centrocosto = item.id_centrocosto;
      this.id_funzione = item.id_funzione;
      this.id_macchina = item.id_macchina;
      this.id_reparto = item.id_reparto;
      this.id_stato = item.id_stato;
      this.id_tipo_get_macchina = item.id_tipo_get_macchina;
      this.marca = item.marca;
      this.matricola = item.matricola;
      this.modello = item.modello;
      this.multi_commessa = item.multi_commessa;
      this.on_line = item.on_line;
      this.schedula = item.schedula;
      this.id_utente = item.id_utente;
      this.ore_disponibilita = item.ore_disponibilita;
      this.costo_orario = item.costo_orario;
      this.specifiche = item.specifiche;
      this.tipo_connessione = item.tipo_connessione;
      this.des_stato = item.des_stato;
      this.des_reparto = item.des_reparto;
      this.des_centrocosto = item.des_centrocosto;
      this.des_funzione = item.des_funzione;
    },
    // Function to clear all the unsaved input values on close
    closeModal() {
      this.autonoma = "";
      this.cod_macchina = "";
      this.data_acquisto = "";
      this.inizio_garanzia = "";
      this.fine_garanzia = "";
      this.des_connessione = "";
      this.des_macchina = "";
      this.id_centrocosto = "";
      this.id_funzione = "";
      this.id_macchina = "";
      this.id_reparto = "";
      this.id_stato = "";
      this.id_tipo_get_macchina = null;
      this.marca = "";
      this.matricola = "";
      this.modello = "";
      this.multi_commessa = "";
      this.on_line = "";
      this.schedula = "";
      this.id_utente = "";
      this.ore_disponibilita = 0;
      this.costo_orario = null;
      this.specifiche = "";
      this.tipo_connessione = "";
      this.des_stato = "";
      this.des_reparto = "";
      this.des_centrocosto = "";
      this.des_funzione = "";
      this.MacchinaFormDialog = false;
    },
    openModal() {
      this.macchina_img = "";
      this.MacchinaFormDialog = true;
      this.costo_orario = 0;
    },
    // TO GO BACK TO Dashboard
    handleClick(value) {
      this.$router.push({
        path: "/home/production/" + value.id_macchina,
      });
    },
    // Function to create a new macchina
    submit() {
      this.overlay = true;
      if (!this.id_macchina) {
        if (this.cod_macchina === "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il campo Codice Macchina è richiesto!";
          this.snackbar_text_color = "white";
          return;
        }
        if (this.des_macchina === "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il campo Des Macchina è richiesto!";
          this.snackbar_text_color = "white";
          return;
        }
        let data = {
          autonoma: this.autonoma,
          cod_macchina: this.cod_macchina,
          data_acquisto: this.data_acquisto,
          inizio_garanzia: this.inizio_garanzia,
          fine_garanzia: this.fine_garanzia,
          date_ins: this.date_ins,
          date_mod: this.date_mod,
          des_connessione: this.des_connessione,
          des_macchina: this.des_macchina,
          id_centrocosto:
            this.id_centrocosto != null ? this.id_centrocosto : "",
          des_centrocosto:
            this.id_centrocosto != null ? this.id_centrocosto.des : "",
          id_funzione: this.id_funzione != null ? this.id_funzione : "",
          des_funzione: this.id_funzione != null ? this.id_funzione.des : "",
          id_macchina: -1,
          id_reparto: this.id_reparto != null ? this.id_reparto : "",
          des_reparto: this.id_reparto != null ? this.id_reparto.des : "",
          id_stato: this.id_stato != null ? this.id_stato : "",
          des_stato: this.id_stato != null ? this.id_stato.des : "",
          id_tipo_get_macchina: this.id_tipo_get_macchina,
          marca: this.marca,
          matricola: this.matricola,
          modello: this.modello,
          multi_commessa: this.multi_commessa,
          on_line: this.on_line,
          schedula: this.schedula,
          id_utente: localStorage.getItem("id"),
          ore_disponibilita: this.ore_disponibilita,
          costo_orario: this.costo_orario,
          specifiche: this.specifiche,
          tipo_connessione: this.tipo_connessione,
          macchina_img: this.macchina_img,
          validita: 1,
        };
        let dataarray = [];
        dataarray.push(data);
        let finale = {
          controller: "Manutenzione",
          method: "PUT",
          richiesta: {
            action: "updatemacchina",
            token: localStorage.getItem("user_token"),
            tabella: dataarray,
          },
        };
        this.$store.dispatch("api", finale).then((res) => {
          this.overlay = false;
          if (res.status == 200) {
            this.getMacchinaList();
            this.snackbar = true;
            this.snackbar_icon = "mdi-check";
            this.snackbar_background = "success";
            this.snackbar_text = "Modifica effettuata con successo!";
            this.snackbar_text_color = "white";
          }
        });
      }
      if (this.id_macchina) {
        let data = {
          autonoma: this.autonoma,
          cod_macchina: this.cod_macchina,
          data_acquisto: this.data_acquisto,
          inizio_garanzia: this.inizio_garanzia,
          fine_garanzia: this.fine_garanzia,
          date_ins: this.date_ins,
          date_mod: this.date_mod,
          des_connessione: this.des_connessione,
          des_macchina: this.des_macchina,
          id_centrocosto:
            this.id_centrocosto != null ? this.id_centrocosto : "",
          des_centrocosto:
            this.id_centrocosto != null ? this.id_centrocosto.des : "",
          id_funzione: this.id_funzione != null ? this.id_funzione : "",
          des_funzione: this.id_funzione != null ? this.id_funzione.des : "",
          id_macchina: this.id_macchina,
          id_reparto: this.id_reparto != null ? this.id_reparto : "",
          des_reparto: this.id_reparto != null ? this.id_reparto.des : "",
          id_stato: this.id_stato != null ? this.id_stato : "",
          des_stato: this.id_stato != null ? this.id_stato.des : "",
          id_tipo_get_macchina: this.id_tipo_get_macchina,
          marca: this.marca,
          matricola: this.matricola,
          modello: this.modello,
          multi_commessa: this.multi_commessa,
          schedula: this.schedula,
          id_utente: localStorage.getItem("id"),
          ore_disponibilita: this.ore_disponibilita,
          costo_orario: this.costo_orario,
          specifiche: this.specifiche,
          tipo_connessione: this.tipo_connessione,
          macchina_img: this.macchina_img,
          validita: 1,
        };
        let dataarray = [];
        dataarray.push(data);
        let finale = {
          controller: "Manutenzione",
          method: "PUT",
          richiesta: {
            action: "updatemacchina",
            token: localStorage.getItem("user_token"),
            tabella: dataarray,
          },
        };
        this.$store.dispatch("api", finale).then((res) => {
          this.overlay = false;
          if (res.status == 200) {
            this.getMacchinaList();
            this.snackbar = true;
            this.snackbar_icon = "mdi-check";
            this.snackbar_background = "success";
            this.snackbar_text = "Modifica effettuata con successo!";
            this.snackbar_text_color = "white";
          }
        });
        const i = this.MacchinaLista.findIndex(
          (it) => it.id_macchina === data.id_macchina
        );
        this.MacchinaLista.splice(i, 1, data);
      }
      this.autonoma = "";
      this.cod_macchina = "";
      this.data_acquisto = "";
      this.inizio_garanzia = "";
      this.fine_garanzia = "";
      this.date_ins = "";
      this.date_mod = "";
      this.des_connessione = "";
      this.des_macchina = "";
      this.id_centrocosto = "";
      this.id_funzione = "";
      this.id_macchina = "";
      this.id_reparto = "";
      this.id_stato = "";
      this.id_tipo_get_macchina = null;
      this.marca = "";
      this.matricola = "";
      this.modello = "";
      this.multi_commessa = "";
      this.on_line = "";
      this.MacchinaFormDialog = false;
      this.ore_disponibilita = 0;
      this.costo_orario = null;
      this.specifiche = "";
      this.tipo_connessione = "";
    },
    // Function to delete Macchina
    deleteMachina() {
      let data = this.selectedMacchina;
      this.overlay = true;
      data.validita = 0;
      let dataarray = [data];
      let del = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatemacchina",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };
      this.$store.dispatch("api", del).then((res) => {
        this.overlay = false;
        if (res.status == 200) {
          this.getMacchinaList();
          this.snackbar = true;
          this.snackbar_icon = "mdi-check";
          this.snackbar_background = "success";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
          this.deleteConfirmationdialog = false;
        }
      });
    },
    // Get Macchina lista
    gettabelle() {
      let req_data = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          filter: "validita = 1",
          tipo:
            "'MACCHINA_STATO','MACCHINA_REPARTO','MACCHINA_FUNZIONE','MACCHINA_CENTRO_COSTO','TIPO_GET_MACCHINA'",
        },
      };
      this.$store.dispatch("api", req_data).then((res) => {
        if (res.status != 204 && res.data != "") {
          this.des_controcosto_list = res.data.MACCHINA_CENTRO_COSTO;
          this.des_funzione_list = res.data.MACCHINA_FUNZIONE;
          this.des_reparto_list = res.data.MACCHINA_REPARTO;
          this.des_stato_list = res.data.MACCHINA_STATO;
          this.tipo_macchina_list = res.data.TIPO_GET_MACCHINA;
        }
      });
    },
    getMacchinaList() {
      let manu_detail = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistamacchine",
          token: localStorage.getItem("user_token"),
        },
      };
      this.loading = true;
      this.progressBar = true;
      this.$store.dispatch("api", manu_detail).then((res) => {
        this.loading = false;
        this.progressBar = false;
        this.MacchinaLista = res.data;
      });
    },
  },
  created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  computed: {
    data_acquisto_formato() {
      return this.data_acquisto
        ? moment(this.data_acquisto, [
            "DD/MM/YYYY hh:mm:ss",
            "YYYY-MM-DD",
          ]).format("DD/MM/YYYY")
        : "";
    },
    inizio_garanzia_formato() {
      return this.inizio_garanzia
        ? moment(this.inizio_garanzia, [
            "DD/MM/YYYY hh:mm:ss",
            "YYYY-MM-DD",
          ]).format("DD/MM/YYYY")
        : "";
    },
    fine_garanzia_formato() {
      return this.fine_garanzia
        ? moment(this.fine_garanzia, [
            "DD/MM/YYYY hh:mm:ss",
            "YYYY-MM-DD",
          ]).format("DD/MM/YYYY")
        : "";
    },
    //Done to get the ordered headers
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },
  mounted() {
    localStorage.setItem(
      "matrice_autorizzazioni",
      JSON.stringify(this.formsPermission)
    );
    this.getMacchinaList();
    // To Populate all list of data require to create a new macchina
    let req_data = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        filter: "validita = 1",
        tipo:
          "'MACCHINA_STATO','MACCHINA_REPARTO','MACCHINA_FUNZIONE','MACCHINA_CENTRO_COSTO','TIPO_GET_MACCHINA'",
      },
    };
    this.$store.dispatch("api", req_data).then((res) => {
      if (res.status != 204 && res.data != "") {
        this.des_controcosto_list = res.data.MACCHINA_CENTRO_COSTO;
        this.des_funzione_list = res.data.MACCHINA_FUNZIONE;
        this.des_reparto_list = res.data.MACCHINA_REPARTO;
        this.des_stato_list = res.data.MACCHINA_STATO;
        this.tipo_macchina_list = res.data.TIPO_GET_MACCHINA;
      }
    });
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
    // To populate main table
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.icon2 {
  margin-top: -7px !important;
}
.v-card1 {
  height: 48px !important;
}
</style>
